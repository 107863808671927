var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "app"
    }
  }, [_c('UpdateApp'), _c('OfflineApp'), _c('BeelseDetectBrowser', {
    ref: "MyBeelseDetectBrowser"
  }), _c('BeelseNotifications', {
    ref: "BCMNotification"
  }), _vm.loaded ? _c('router-view', {
    staticClass: "fill-height"
  }) : [_c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.error ? _c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-icon', {
    attrs: {
      "color": "error",
      "size": "100"
    }
  }, [_vm._v(" fas fa-exclamation-circle ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', [_vm._v(_vm._s(_vm.$t('Error')))])])], 1), _c('v-row', [_c('v-col', [_c('p', [_vm._v(_vm._s(_vm.errorMessage))])])], 1)], 1) : _c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "img-background-app"
  }), _c('v-row', [_c('v-col', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": true,
      "color": "orange",
      "size": 200,
      "display-logo": false,
      "logo": "B"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('p', {
    staticClass: "text-h4 mt-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('Loading')) + " ")])])], 1)], 1)], 1)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }