<template>
  <v-app id="app">
    <UpdateApp></UpdateApp>
    <OfflineApp></OfflineApp>
    <BeelseDetectBrowser ref="MyBeelseDetectBrowser"></BeelseDetectBrowser>
    <BeelseNotifications ref="BCMNotification"></BeelseNotifications>
    <router-view v-if="loaded" class="fill-height"></router-view>
    <template v-else>
      <v-container
        fill-height
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col v-if="error" align="center">
            <v-row>
              <v-col>
                <v-icon
                  color="error"
                  size="100"
                >
                  fas fa-exclamation-circle
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>{{ $t('Error') }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else align="center">
            <div class="img-background-app"></div>
            <v-row>
              <v-col>
                <SpinnerBeelse
                  :multiple="true"
                  color="orange"
                  :size="200"
                  :display-logo="false"
                  logo="B"
                >
                </SpinnerBeelse>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="text-h4 mt-12">
                  {{ $t('Loading') }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue';

import {
  BeelseNotifications,
  BeelseDetectBrowser,
  EventBus,
  OfflineApp,
  SpinnerBeelse,
  UpdateApp,
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./app.i18n');

Vue.prototype.$appConfig = {
  user: {},
  userBrands: [],
  currentBrand: null,
  customization: {},
  isAuthenticated: false,
  redirectAfterLogin: null,
  paymentMethods: null,
  fundingInstructions: null,
};

export default {
  name: 'App',
  components: {
    BeelseNotifications,
    BeelseDetectBrowser,
    OfflineApp,
    UpdateApp,
    SpinnerBeelse,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      user: this.$appConfig.user,
      loaded: false,
      error: false,
      errorMessage: '',
    };
  },
  created() {
    // console.log('App.vue:Created()');
    this.$router.onReady(()=>{
      this.init();
    });

    this.$router.onError(()=>{
      this.loaded = false;
      this.errorMessage = 'Router error';
      this.error = true;
    });
    EventBus.$on('refreshAuthToken', () => {
      this.$appConfig.redirectAfterLogin = `${window.location.hash.replace('#','')}`;
      /**
       * Get new token
       */
      this.$apiInstance.authRefresh().then((data) => {
        if (data && data.token) {
          /**
           * Replace cookie
           */
          const date = new Date();
          date.setHours(date.getHours() + 8);
          const dateString = date.toGMTString();

          this.$setCookie('_pu', data._id, dateString);
          this.$setCookie('_pp', data.token, dateString);
          /**
           * Update credentials throught the login process
           */
          EventBus.$emit('globalLoginProcessComplete');
        }
      });
    });

    /**
     * Refresh the application if not loaded after 5sec
     * The goal is to avoid blank screen (now with spinner) on ServiceWorker post update
     */
    setTimeout(
      function() {
        if(!this.loaded) {
          navigator.serviceWorker?.getRegistrations()
            .then((registrations) => {
              for(const registration of registrations) {
                registration.unregister();
              }
              document.location.reload();
            })
            .finally(()=>{
              this.errorMessage = 'Loading';
              this.error = true;
            });
        }
      }.bind(this),
      2000
    );
  },
  mounted() {
    Vue.prototype.$notification = this.$refs.BCMNotification;
  },
  methods: {
    init() {
      // console.log('App.vue:init()');
      this.loaded = false;
      this.$appConfig.isAuthenticated = false;
      this.$vuetify.lang.current = this.$userLocale;
      /**
       * Configure env variables
       */
      if (process.env.NODE_ENV === 'development') {
        this.$apiInstance.apiClient.basePath = process.env.VUE_APP_API_URL;
        this.$appConfig.hotjarId = process.env.VUE_APP_HOTJAR_ID;
        this.$stripeConfig.pk = process.env.VUE_APP_STRIPE_PK;
        this.getCustomization();
      } else if (process.env.NODE_ENV === 'production') {
        this.$http.get('env_var').then(
          (response) => {
            this.$apiInstance.apiClient.basePath = response.data.WS_URL;
            this.$appConfig.hotjarId = response.data.HOTJAR_ID;
            this.$stripeConfig.pk = response.data.STRIPE_PK;
            this.getCustomization();
          },
          (error) => {
            this.errorMessage = `${this.$t('BadConfig')} - status: ${
              error.status
            }`;
            this.error = true;
          }
        );
      }
    },
    getCustomization() {
      // console.log('App.vue:getCustomization()');
      this.$apiInstance.getThemeByDomainByDomain(document.location.origin).then((data) => {
        this.$appConfig.customization.login = data;
        /**
         * Verify domain
         * - If not the same domain, redirect to the proper domain
         * - Ignore redirect in development mode
         */
        if(this.$appConfig.customization.login.environment === 'development') {
          // eslint-disable-next-line
          console.info('Domain Theme redirection is disabled in development mode');
          if(this.$appConfig.customization.login.bcmURL !== document.location.origin) {
            // eslint-disable-next-line
            console.info(`-> Should be redirect to: ${this.$appConfig.customization.login.bcmURL}`);
          }else{
            // eslint-disable-next-line
            console.info('-> No redirection needed: domain theme and current domain are the same');
          }
        }
        if(this.$appConfig.customization.login.environment !== 'development' && this.$appConfig.customization.login.bcmURL !== document.location.origin) {
          // eslint-disable-next-line
          console.log('Redirect to CMT Theme location');
          document.location.replace(document.location.href.replace(document.location.origin, this.$appConfig.customization.login.bcmURL));
        }else{
          /**
           * Load App
           */
          this.loaded = true;
        }
      }, (error) => {
        // eslint-disable-next-line
        console.error(error);
        this.errorMessage = 'Loading';
        this.error = true;
      });
    },
  },
};
</script>
