import Vue from 'vue';
import Router from 'vue-router';
import { Page404 } from '@cloudmanufacturingtechnologies/portal-components';

Vue.use(Router);

const isAuth = (to, from, next) => {
  if (Vue.prototype.$appConfig.isAuthenticated) {
    //If already Auth, go to next()
    next();
    return;
  }
  /**
   * If not Authenticated, save the current URL to redirect after the login process
   * We should save the window location hash (because the current 'from' is not valid when the user is not Authenticated) 
   * without the '#' for correctly match the route after the process
   */
  if(window.location.hash && !Vue.prototype.$appConfig.redirectAfterLogin) {
    Vue.prototype.$appConfig.redirectAfterLogin = `${window.location.hash.replace('#','')}`;
    // console.log(`Router will redirect after login to: ${Vue.prototype.$appConfig.redirectAfterLogin}`);
  }
  next('/login');
};

export default new Router({
  routes: [
    {
      path: '/register/offer/:offer',
      name: 'Register',
      component: () => import(/* webpackChunkName: "Register" */ './pages/register/PageRegister.vue'),
    },
    {
      path: '/register/:registrationUUID/token/:tokenUUID/validate',
      name: 'RegisterValidate',
      component: () => import(/* webpackChunkName: "RegisterValidate" */ './pages/registerValidate/PageRegisterValidate.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "Login" */ './pages/login/PageLogin.vue'),
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter: isAuth,
      component: () => import(/* webpackChunkName: "Logout" */ './pages/logout/PageLogout.vue'),
    },
    /**
     * CMT
     */
    {
      path: '/',
      name: 'Home',
      beforeEnter: isAuth,
      component: () => import(/* webpackChunkName: "Home" */ './pages/index/PageIndex.vue'),
      children: [
        /**
         * User Preferences
         */
        {
          path: '/user/account',
          name: 'UserAccount',
          component: () => import(/* webpackChunkName: "UserAccount" */ './pages/userAccount/PageUserAccount.vue'),
        },
        /**
         * CMT Warehouse
         */
        {
          path: '/:brandUUID/dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "Dashboard" */ './pages/dashboard/PageDashboard.vue'),
        },
        {
          path: '/:brandUUID/administration',
          name: 'Administration',
          component: () => import(/* webpackChunkName: "Administration" */ './pages/administration/PageAdministration.vue'),
          props: true,
          children: [
            {
              path: 'general',
              name: 'AdministrationGeneral',
              component: () => import(/* webpackChunkName: "AdministrationGeneral" */ './pages/administration/general/PageAdministrationGeneral.vue'),
            },
            {
              path: 'payment/methods',
              name: 'AdministrationPaymentMethods',
              component: () => import(/* webpackChunkName: "AdministrationPaymentMethods" */ './pages/administration/paymentMethods/PageAdministrationPaymentMethods.vue'),
            },
            {
              path: 'subscription/billing',
              name: 'AdministrationSubscription',
              component: () => import(/* webpackChunkName: "AdministrationSubscription" */ './pages/administration/subscription/PageAdministrationSubscription.vue'),
            },
            {
              path: 'users',
              name: 'AdministrationUsers',
              component: () => import(/* webpackChunkName: "AdministrationUsers" */ './pages/administration/users/PageAdministrationUsers.vue'),
            },
            {
              path: 'managed/startups',
              name: 'AdministrationManagedStartups',
              component: () => import(/* webpackChunkName: "AdministrationManagedStartups" */ './pages/administration/managedStartups/PageAdministrationManagedStartups.vue'),
            },
            {
              path: 'sharing/parts',
              name: 'AdministrationSharingParts',
              component: () => import(/* webpackChunkName: "AdministrationSharingParts" */ './pages/administration/sharingParts/PageAdministrationSharingParts.vue'),
            },
          ],
        },
        {
          path: '/:brandUUID/warehouse/part/:partUUID',
          name: 'Part',
          component: () => import(/* webpackChunkName: "Part" */ './pages/part/PagePart.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Warehouse',
                link: '#/:brandUUID/warehouse',
              },
              {
                name: 'Part',
              },
            ],
          },
        },
        {
          path: '/:brandUUID/warehouse/',
          name: 'Warehouse',
          component: () => import(/* webpackChunkName: "Warehouse" */ './pages/warehouse/PageWarehouse.vue'),
        },
        {
          path: '/:brandUUID/warehouse/part/:partUUID/orders',
          name: 'PartOrders',
          component: () => import(/* webpackChunkName: "PartOrders" */ './pages/partOrders/PagePartOrders.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Warehouse',
                link: '#/:brandUUID/warehouse',
              },
              {
                name: 'Part',
                link: '#/:brandUUID/warehouse/part/:partUUID',
              },
              {
                name: 'PartOrders',
              },
            ],
          },
        },
        {
          path: '/:brandUUID/warehouse/part/:partUUID/quotes',
          name: 'PartQuotes',
          component: () => import(/* webpackChunkName: "PartQuotes" */ './pages/partQuotes/PagePartQuotes.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Warehouse',
                link: '#/:brandUUID/warehouse',
              },
              {
                name: 'Part',
                link: '#/:brandUUID/warehouse/part/:partUUID',
              },
              {
                name: 'PartQuotes',
              },
            ],
          },
        },
        {
          path: '/:brandUUID/quote/',
          name: 'Quotes',
          component: () => import(/* webpackChunkName: "Quotes" */ './pages/quotes/PageQuotes.vue'),
        },
        {
          path: '/:brandUUID/quote/:quoteUUID',
          name: 'Quote',
          component: () => import(/* webpackChunkName: "Quote" */ './pages/quote/PageQuote.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Quotes',
                link: '#/:brandUUID/quote/',
              },
              {
                name: 'Quote',
              },
            ],
            parent: ['PartQuotes', 'Dashboard'],
          },
        },
        {
          path: '/:brandUUID/order/',
          name: 'Orders',
          component: () => import(/* webpackChunkName: "Orders" */ './pages/orders/PageOrders.vue'),
        },
        {
          path: '/:brandUUID/order/:orderUUID',
          name: 'Order',
          component: () => import(/* webpackChunkName: "Order" */ './pages/order/PageOrder.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Orders',
                link: '#/:brandUUID/order/',
              },
              {
                name: 'Order',
              },
            ],
            parent: ['PartOrders', 'Dashboard'],
          },
        },
        {
          path: '/:brandUUID/traceability/',
          name: 'Traceability',
          component: () => import(/* webpackChunkName: "Traceability" */ './pages/traceability/PageTraceability.vue'),
        },
        {
          path: '/:brandUUID/user/:userUUID',
          name: 'User',
          component: () => import(/* webpackChunkName: "User" */ './pages/user/PageUser.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Administration',
                link: '#/:brandUUID/administration/',
              },
              {
                name: 'User',
              },
            ],
          },
        },
        {
          path: '/:brandUUID/basket/',
          name: 'Basket',
          component: () => import(/* webpackChunkName: "Basket" */ './pages/basket/PageBasket.vue'),
        },
      ]
    },
    {
      path: '*',
      name: '404',
      component: Page404,
    },
  ],
});
